import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private registerUrl = `${environment.api}auth/register`;
  private loginUrl = `${environment.api}admin-login`;
  private updateUrl = `${environment.api}customer`;
  private userUrl = `${environment.api}auth/user`;
  private forgotUrl = `${environment.api}forget-password`;
  private savePasswordeUrl = `${environment.api}change-password`;

  constructor(private http: HttpClient) {}

  registerUser(user) {
    return this.http.post<any>(this.registerUrl, user);
  }

  loginUser(user) {
    return this.http.post<any>(this.loginUrl, user);
  }

  forgot(user) {
    return this.http.post<any>(this.forgotUrl, user);
  }

  getUser() {
    const usr = localStorage.getItem('user');
    if (usr) {
      return JSON.parse(usr);
    } else {
      return false;
    }
  }

  updateUser(user) {
    return this.http.post<any>(this.updateUrl, user);
  }

  savePassword(user) {
    return this.http.post<any>(this.savePasswordeUrl, user);
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  isActive() {
    const usr = localStorage.getItem('user');
    if (this.loggedIn() && usr) {
      const user = JSON.parse(usr);
      if (user.status === 1) {
        return true;
      }
    }
    return false;
  }

  isAdmin() {
    const usr = localStorage.getItem('user');
    if (this.loggedIn() && usr) {
      const user = JSON.parse(usr);
      if (user.is_admin == 1) {
        return true;
      }
    }
    return false;
  }

  refreshUserInfo() {
    if (this.loggedIn() && !this.isActive()) {
      this.http.get<any>(this.userUrl).subscribe(
        res => {
          if (res.success) {
            localStorage.setItem('user', JSON.stringify(res.data));
          }
        }
      );
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
